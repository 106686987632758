import React from "react"
import Lottie from "lottie-react-web"
import animation from "../images/27572-success-animation.json"
import { useFormContext } from "react-hook-form"

const Success = (props: any) => {
  const methods = useFormContext()

  return (
    <div className="w-full" id="form2">
      <div className="text-lg sm:text-l text-center block  tracking-wide text-gray-700 py-4">
        お申し込みありがとうござました。
        <br />
        <br />
        また、日経MJ50周年を記念し、日経MJ50周年プレミアムゼミナールを2日に分けて開催します。
        <br />
        こちらも無料で視聴できるオンラインセミナーとなっておりますので
        <br />
        合わせてご登録いただければ幸いです。
        <br />
        <br />
        <h2>
          <a
            className="text-blue-500"
            href="https://events.nikkei.co.jp/37845/"
          >
            https://events.nikkei.co.jp/37845/
          </a>
        </h2>
      </div>
      <div className="">
        <Lottie
          options={{ animationData: animation, loop: false }}
          height={400}
        />
      </div>
    </div>
  )
}

export default Success
