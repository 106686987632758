import React, { useState, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { TextInput, DatePicker, Check } from "../components/ui"
import { usePostalJp } from "use-postal-jp"
import Data from "./Store.json"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

const Form = () => {
  const methods = useFormContext()
  const [value, setValue] = useState("")

  const {
    address,
    error,
    pending,
    sanitizedCode,
    setPostalCode,
  } = usePostalJp()

  const Store = Data.data
  useEffect(() => {
    Store.map((d) => {
      if (document.URL.match(d.id)) {
        methods.setValue("store", d.store)
        methods.setValue("tel", d.tel)
        methods.setValue("sendmail", d.mail)
        methods.setValue("address", d.address)
      }
    })

    setPostalCode(value)
  }, [value, setPostalCode])
  const Plans = [
    { id: 1, value: "お試し配達14日間", price: "￥0" },
    { id: 2, value: "購読", price: "￥4,900/月" },
  ]
  const Paper = [
    { id: 1, value: "日経産業新聞", price: "￥4,000/月" },
    { id: 2, value: "日経MJ新聞", price: "￥2,300/月" },
    { id: 3, value: "東京中日スポーツ", price: "￥3,039/月" },
    { id: 4, value: "デイリースポーツ", price: "￥3,353/月" },
  ]
  const Plan = methods.watch("plan")
  const ChangeZip = (e: any) => {
    setValue(e)

    methods.setValue("pref", address.prefecture, {
      shouldDirty: true,
      shouldValidate: true,
    })
    {
      address.address1 &&
        methods.setValue("city", address.address1 + address.address2, {
          shouldDirty: true,
          shouldValidate: true,
        })
    }

    // methods.setValue("addr", address.address2, {
    //   shouldDirty: true,
    //   shouldValidate: true,
    // })
  }

  return (
    <>
      <input type="hidden" name="store" ref={methods.register} />
      <input type="hidden" name="tel" ref={methods.register} />
      <input type="hidden" name="sendmail" ref={methods.register} />
      <input type="hidden" name="address" ref={methods.register} />
      <div className="w-full shadow-2xl p-8" id="form1" key="form1">
        <div className="flex flex-col -mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mb-0">
            <h2 className="leading-relaxed ...">
              こちらからお申し込み頂くことで、日経MJの無料配達が通常の3回配達から9回となります。
              <br />
              お届け日は、
              <span className="font-bold ...">
                50周年特別記念号の5/31（月）
              </span>
              から
              <span className="font-bold ...">
                ヒット商品番付掲載予定の6/16（水）の次の発行日の6/18（金）
              </span>
              までとなっております。 なお、ご購読の義務は一切ありません。
              <br />
              また、ありがちな電話での勧誘などもいたしません。お客様へのご連絡はメールでの非同期通信のみですのでお気軽にお申し込み下さい。
            </h2>
            <br />
            <div>
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>日経MJとは？</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    コンビニなどではほとんど流通していない、知る人ぞ知るビジネスに役立つ情報満載の週３回の新聞です。
                    トレンドの先端を独自の視点でとらえた情報は多くのマスコミ関係者に愛読される一方で、一部の主婦など情報感度の高い消費者層にも届いています。
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            {/* <h3 className="text-center text-lg">日経MJとは？</h3>
            <br />
            <p>
              コンビニなどではほとんど流通していない、知る人ぞ知るビジネスに役立つ情報満載の週３回の新聞です。
              トレンドの先端を独自の視点でとらえた情報は多くのマスコミ関係者に愛読される一方で、一部の主婦など情報感度の高い消費者層にも届いています。
            </p> */}
            {/* <label
              className="uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2 "
              htmlFor="plan"
            >
              お申込内容
              <span
                className={`ml-1 ${
                  Plan && !methods.errors.plan
                    ? "text-green-500 border border-green-500"
                    : "text-red-500 border border-red-500"
                }`}
              >
                {Plan && !methods.errors.plan ? "OK" : "必須"}
              </span>
              <div className="flex flex-col sm:flex-row">
                {Plans.map((d) => (
                  <div key={d.id}>
                    <input
                      type="radio"
                      name="plan"
                      value={d.value}
                      className="m-2 form-radio h-5 w-5 text-indigo-600 align-middle "
                      ref={methods.register}
                    />
                    <span
                      className="align-middle text-gray-700"
                      onClick={() => methods.setValue("plan", d.value)}
                    >
                      {`日本経済新聞の${d.value} ${d.price}`}
                    </span>
                  </div>
                ))}
              </div>
            </label>
            <p className="text-red-500 text-xs italic">
              {methods.errors.plan?.message}
            </p> */}
          </div>

          <div className="flex flex-col">
            <h1 className="text-center text-lg mt-4">お届け先情報入力</h1>
            <div className="w-full px-3 mt-6 mb-6 md:mb-0">
              <TextInput
                label="お名前または会社名(部署名)"
                type="text"
                name="name1"
                auto="name"
                required={true}
                footer={methods.errors.name1?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="担当者様のお名前(任意)"
                type="text"
                name="name2"
                auto="name"
                required={false}
                footer={methods.errors.name2?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row -mx-3 mb-6">
          <div className="w-full sm:w-1/4 text-center mb-6">ご住所</div>
          <div className="w-full flex flex-col">
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="郵便番号(住所自動入力)"
                type="text"
                name="zip"
                auto="postal-code"
                required={true}
                onBlur={(e: any) => ChangeZip(e.target.value)}
                onChange={(e: any) => ChangeZip(e.target.value)}
                footer={methods.errors.zip?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="都道府県"
                type="text"
                name="pref"
                auto="address-level1"
                required={true}
                footer={methods.errors.pref?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="市区町村"
                type="text"
                name="city"
                auto="address-level2"
                required={true}
                footer={methods.errors.city?.message}
              />
            </div>
            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="番地"
                type="text"
                name="addr"
                auto="address-level3"
                place="例）１−１"
                required={true}
                footer={methods.errors.addr?.message}
              />
            </div>

            <div className="w-full sm:w-3/4 px-3 mb-6 md:mb-0">
              <TextInput
                label="建物名・部屋番号"
                type="text"
                name="building"
                auto="address-level4"
                place="例）日経ビル　１０１"
                required={false}
                footer={methods.errors.building?.message}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col -mx-3 mb-6">
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="メールアドレス"
                type="email"
                name="email"
                auto="email"
                required={true}
                footer={methods.errors.email?.message}
              />
            </div>
            <div className="w-full px-3 mb-6 md:mb-0">
              <TextInput
                label="お電話番号"
                type="tel"
                name="telephone"
                auto="tel"
                required={true}
                footer={methods.errors.telephone?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            {/* <DatePicker
              label="お届け開始日"
              type="date"
              name="date"
              required={true}
              footer={methods.errors.date?.message}
            />
            <p className="text-gray-600 text-xs italic">
              ※お届けは最短で二日後からになります
            </p> */}

            {/* <Check
              label={`日本経済新聞の他に${Plan}したい新聞（任意）`}
              type="checkbox"
              name="paper"
              required={false}
              value={Paper}
              footer={methods.errors.paper?.message}
            />
            <p className="text-gray-600 text-xs italic py-4">
              {`日本経済新聞の他に${Plan}をご希望の方は選択してください`}
            </p> */}
            <TextInput
              label="連絡事項（任意）"
              type="textarea"
              name="message"
              required={false}
              footer={methods.errors.message?.message}
            />

            <p className="text-gray-600 text-xs italic">
              ご不明点やご要望がございましたらご入力ください
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
