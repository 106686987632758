import React, { useState, useEffect } from "react"
import { useFormContext, Controller } from "react-hook-form"
import "flatpickr/dist/themes/material_green.css"
import Flatpickr from "react-flatpickr"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from "date-fns/locale/ja"
import dateFormat from "dateformat"

registerLocale("ja", ja)

type InputData = {
  label: string
  type: string
  name: string
  footer: string
  value?: string
  place?: string
  defaultValues?: string
  onChange?: any
  onBlur?: any
  auto?: string
  required: boolean
  control?: any
}
type SelectData = {
  label: string
  type: string
  name: string
  footer: string
  value: any
  defaultValues?: string
  onChange?: any
  onBlur?: any
  auto?: string
  required: boolean
  control?: any
}
const TextInput: React.FC<InputData> = (props) => {
  const {
    label,
    type,
    name,
    footer,
    value,
    place,
    defaultValues,
    onChange,
    onBlur,
    auto,
    required,
  } = props
  const methods = useFormContext()

  const watch = methods.watch(name)
  const trans = (e: any) => {
    const str = e.target.value
    methods.setValue(
      name,
      str.replace(
        /[Ａ-Ｚａ-ｚ０-９]/g,
        function (s: any) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
        },
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      )
    )
  }

  let elem = (
    <input
      className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
        watch && !methods.errors.name ? "border-green-500" : "border-0"
      }`}
      id={name}
      name={name}
      type={type}
      ref={methods.register}
      onChange={onChange}
      onBlur={trans}
      value={value}
      defaultValue={defaultValues}
      placeholder={place}
      autoComplete={auto}
    />
  )
  if (name == "zip") {
    elem = (
      <input
        className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
          watch && !methods.errors.name ? "border-green-500" : "border-0"
        }`}
        id={name}
        name={name}
        type={type}
        ref={methods.register}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValues}
        placeholder=""
        autoComplete={auto}
      />
    )
  }
  if (type === "textarea") {
    elem = (
      <textarea
        className={`text-lg sm:text-xl no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none ${
          watch && !methods.errors.name ? "border-green-500" : "border-0"
        }`}
        id={name}
        name={name}
        ref={methods.register}
        onChange={onChange}
        onBlur={trans}
        value={value}
        defaultValue={defaultValues}
        placeholder=""
        autoComplete={auto}
      ></textarea>
    )
  }

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2"
        htmlFor={name}
      >
        {label}
        {required && (
          <span
            className={`ml-1 ${
              watch && !methods.errors.name
                ? "text-green-500 border border-green-500"
                : "text-red-500 border border-red-500"
            }`}
          >
            {watch && !methods.errors.name ? "OK" : "必須"}
          </span>
        )}
      </label>
      {elem}
      {footer && <p className="text-red-500 text-xs italic mb-2">{footer}</p>}
    </>
  )
}
const DatePicker: React.FC<InputData> = (props) => {
  const Japanese = require("flatpickr/dist/l10n/ja.js").default.ja

  const {
    label,
    type,
    name,
    footer,
    value,
    defaultValues,
    onChange,
    control,
    auto,
    required,
  } = props
  const methods = useFormContext()
  const startDate = new Date()
  const d = new Date()
  // const MinDate = d.setDate(d.getDate() + 2)
  const watch = methods.watch(name)

  const handleChange = (e: any) => {
    // const Date = methods.getValues(name)
    const FormatDate = dateFormat(e, "isoDate")
    methods.setValue(name, FormatDate)
    // console.log(FormatDate)
  }

  // const [date, setDate] = useState(new Date())

  let elem = (
    // <Controller
    //   name={name}
    //   render={(props) => (
    //     <Flatpickr
    //       className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white `}
    //       options={{
    //         locale: Japanese,
    //         minDate: MinDate,
    //         altInput: true,
    //         altFormat: "Y-m-d",
    //         dateFormat: "Y-m-d",
    //         disableMobile: true,
    //       }}
    //       value={watch}
    //       // select={props.value}
    //       onChange={handleChange}
    //     />
    //   )}
    //   control={control}
    // />
    // <Controller
    //   as={
    //     <Flatpickr
    //       options={{
    //         locale: Japanese,
    //         minDate: MinDate,
    //         altInput: true,
    //         altFormat: "Y-m-d",
    //         dateFormat: "Y-m-d",
    //         disableMobile: true,
    //       }}
    //     />
    //   }
    //   onChange={(e: any) => props.onChange(e)}
    //   name={name}
    //   // defaultValue={MinDate}
    //   control={control}
    //   className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white `}
    // />
    // <Controller
    //   as={
    //     <ReactDatePicker
    //       locale="ja"
    //       className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white `}
    //       // placeholderText="Select date"
    //       onChange={(e) => props.onChange(e)}
    //       // selected={props.value}
    //       dateFormat="yyyy-MM-dd"
    //       minDate={new Date(d.setDate(d.getDate() + 2))}
    //       // customInput={
    //       //   <button className="w-full shadow bg-blue-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">{}</button>
    //       // }
    //     />
    //   }
    //   onChange={(e: any) => props.onChange(e)}
    //   name={name}
    //   // defaultValue={MinDate}
    //   control={control}
    //   className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white `}
    // />
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <ReactDatePicker
          locale="ja"
          className={`text-lg sm:text-xl appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white `}
          // placeholderText="Select date"
          onChange={handleChange}
          // selected={handleChange}
          value={watch}
          dateFormat="yyyy-MM-dd"
          minDate={new Date(d.setDate(d.getDate() + 2))}
          // customInput={
          //   <button className="w-full shadow bg-blue-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded">{}</button>
          // }
        />
      )}
    />
  )

  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2"
        htmlFor={name}
      >
        {label}
        {required && (
          <span
            className={`ml-1 ${
              watch && !methods.errors.name
                ? "text-green-500 border border-green-500"
                : "text-red-500 border border-red-500"
            }`}
          >
            {watch && !methods.errors.name ? "OK" : "必須"}
          </span>
        )}
      </label>
      {elem}
      {footer && <p className="text-red-500 text-xs italic mb-2">{footer}</p>}
    </>
  )
}
const Check: React.FC<SelectData> = (props) => {
  const {
    label,
    type,
    name,
    footer,
    value,
    defaultValues,
    onChange,
    control,
    auto,
    required,
  } = props
  const methods = useFormContext()
  const watch = methods.watch(name)
  const [focused, changeFocused] = useState(false)
  let elem = (
    <>
      <div className="flex flex-col justify-center ">
        <div className="flex flex-col">
          {value.map((d: any) => (
            <label className="inline-flex items-center mt-3" key={d.id}>
              <input
                type={type}
                name={name}
                value={d.value}
                className="form-checkbox h-5 w-5 text-blue-600"
                aria-label={name}
                ref={methods.register}
              />
              <span className="ml-2 text-gray-700">
                {d.value}
                {"　"}{" "}
                {methods.watch("plan") === "お試し配達14日間" ? "" : d.price}
              </span>
            </label>

            // {/* <label className="flex justify-start items-start" key={d.id}>
            //   <div className="bg-white border-2 rounded border-gray w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 my-1 focus-within:border-blue-500">
            //     <input
            //       className="opacity-0 absolute"
            //       type={type}
            //       name={name}
            //       value={d.value}
            //       // onFocus={() => changeFocused(true)}
            //       // onBlur={() => changeFocused(false)}
            //       // onChange={() => setValue(`${name}`, value)}
            //       aria-label={name}
            //       ref={methods.register}
            //     />
            //     <svg
            //       className="fill-current hidden w-4 h-4 text-green pointer-events-none"
            //       viewBox="0 0 20 20"
            //     >
            //       <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
            //     </svg>

            //     <div className="select-none my-1"> {d.value}</div>
            //   </div>
            // </label> */}
          ))}
        </div>
      </div>
    </>
  )
  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-sm sm:text-base font-bold mb-2"
        htmlFor={name}
      >
        {label}
        {required && (
          <span
            className={`ml-1 ${
              watch && !methods.errors.name
                ? "text-green-500 border border-green-500"
                : "text-red-500 border border-red-500"
            }`}
          >
            {watch && !methods.errors.name ? "OK" : "必須"}
          </span>
        )}
      </label>
      {elem}
      {footer && <p className="text-red-500 text-xs italic mb-2">{footer}</p>}
    </>
  )
}
export { TextInput, DatePicker, Check }
