import dateFormat from "dateformat"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"

const Conf = () => {
  const methods = useFormContext()
  const values = methods.getValues()
  useEffect(() => {
    console.log(values.paper)
  }, [])

  return (
    <div className="w-full" id="form2">
      <div className="text-lg sm:text-2xl text-center block  tracking-wide text-gray-700 py-4">
        入力内容をご確認ください
      </div>

      <div className="rounded-md shadow-2xl p-8">
        {/* <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          お申込内容
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.plan}
        </h5> */}
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          お名前または会社名（部署名）
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.name1}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          担当者様のお名前
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.name2}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          ご住所
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          〒{values.zip}
          <br />
          {values.pref}
          {values.city}
          {values.addr}
          <br />
          {values.building}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          メールアドレス
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.email}
        </h5>
        <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          電話番号
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.telephone}
        </h5>
        {/* <label className="block  tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
          お届け開始日
        </label>
        <h5 className="block  tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
          {values.date}
        </h5> */}

        {/* {values.paper && (
          <>
            <label className="block tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
              {`その他に${values.plan}したい新聞`}
            </label>
            <h5 className="block tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
              {values.paper.map((d: any) => {
                return <div>{d}</div>
              })}
            </h5>
          </>
        )} */}

        {values.message && (
          <>
            <label className="block tracking-wide text-gray-700 text-sm sm:text-lg mb-2">
              連絡事項
            </label>
            <h5 className="block tracking-wide text-gray-700 text-md sm:text-xl font-bold mb-2">
              {values.message}
            </h5>
          </>
        )}
      </div>

      <div className="align-center bg-yellow-500 rounded-md shadow-2xl p-8 m-4">
        <ul className="list-none">
          <li className="py-2">
            {`お届け先のご確認でご連絡させて頂く場合がございます。ご了承下さい。`}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Conf
