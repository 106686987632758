import React, { ReactElement, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Form from "../components/Form"
import Conf from "../components/Conf"
import Success from "../components/success"
import Error from "../components/error"
import { DevTool } from "@hookform/devtools"
import { AnimatePresence, motion } from "framer-motion"
import scrollTo from "gatsby-plugin-smoothscroll"
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import Loader from "react-loader-spinner"
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

interface Props {}

const schema = yup.object().shape({
  // plan: yup.string().required(`お申込内容を選択してください`),
  name1: yup.string().required(`お名前か会社名を入力してください`),
  zip: yup
    .string()
    .matches(/^[0-9０-９]/, "数字を入力して下さい")
    .min(7, "７桁の数字を入力して下さい")
    .max(7, "７桁の数字を入力して下さい")
    .required(`郵便番号を入力してください`),
  pref: yup.string().required(`都道府県を入力してください`),
  city: yup.string().required(`市区町村を入力してください`),
  addr: yup.string().required(`番地を入力してください`),
  telephone: yup
    .string()
    .matches(
      /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
      "有効な電話番号を入力してください"
    )
    .required(`連絡先のお電話番号を入力してください`),
  email: yup
    .string()
    .email(`正しいメールアドレスを入力してください`)
    .required(`お客様のメールアドレスを入力してください`),
  // date: yup.string().required(`お届け開始日を指定してください`),
})
const variants = {
  enter: () => {
    return {
      opacity: 0,
    }
  },
  center: {
    opacity: 1,
  },
  exit: () => {
    return {
      opacity: 0,
    }
  },
  modalenter: () => {
    return {
      x: -1000,
      opacity: 0,
    }
  },
  modalcenter: {
    x: 0,
    opacity: 1,
  },
  modalexit: () => {
    return {
      x: 1000,
      opacity: 0,
    }
  },
}
function Index(_props: Props): ReactElement {
  const [store, setStore] = React.useState("")
  const [plan, setPlan] = React.useState("")
  useEffect(() => {
    setStore(methods.getValues("store"))
    // setPlan(methods.getValues("plan"))
  }, [store])
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
    defaultValues: {
      // plan: "お試し配達14日間",
      // addr: "",
    },
  })
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    getValues,
    reset,
    control,
    trigger,
    formState: { isSubmitting, isSubmitSuccessful },
  } = methods
  const [page, setPage] = React.useState(1)
  const [showModal, setShowModal] = React.useState(false)
  const { promiseInProgress } = usePromiseTracker()
  const Plan = methods.watch("plan")
  const FAKE_GATEWAY_URL =
    "https://tanviy45uf.execute-api.ap-northeast-1.amazonaws.com/MJ50"

  const onSubmit = () => {
    const data = methods.getValues()
    // console.log(JSON.stringify(data))

    if (store !== "") {
      trackPromise(
        fetch(FAKE_GATEWAY_URL, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }).then((response) => {
          if (response.ok) {
            setPage(3)
            scrollTo("html")
            reset()
          }
        })
        // .catch((error) => {
        //   setPage(4)
        //   scrollTo("html")
        // })
      )
    } else {
      setPage(4)
      scrollTo("html")
    }
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        title=" MJ50周年記念特設ページ"
        meta={[
          {
            name: "description",
            content: " MJ50周年記念特設ページ",
          },
        ]}
      />
      <div className="bg-gray-100">
        <div className="">
          <nav className="flex flex-wrap items-center justify-center sm:justify-between p-6 ">
            <div className="flex flex-row items-center flex-shrink-0 mr-6 text-white">
              <img
                src="/images/logo_50th.jpg"
                className="object-contain h-16 sm:h-24 ml-4"
              />
              <img
                src="/images/mj.svg"
                className="object-contain h-16 sm:h-24 mr-4"
              />
              {/* <StaticImage src={logo} alt="ロゴ画像" /> */}
              <span className="text-lg sm:text-xl font-bold tracking-tight text-black text-center">
                {`ニュースサービス日経${store}`}
                <br />
                MJ50周年記念特設ページ
              </span>
            </div>
          </nav>
        </div>

        <div className="container max-w-4xl mx-auto mt-10 ">
          <div className="overflow-hidden relative bg-white shadow-sm rounded-md">
            <AnimatePresence>
              {promiseInProgress && (
                <motion.div
                  key="modal"
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                  }}
                  className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <Loader
                      type="Oval"
                      color="#2BAD60"
                      height="100"
                      width="100"
                    />
                  </div>
                </motion.div>
                // <div className="w-full h-100 justify-center items-center flex">

                // </div>
              )}
              {showModal ? (
                <>
                  <motion.div
                    key="modal"
                    variants={variants}
                    initial="modalenter"
                    animate="modalcenter"
                    exit="modalexit"
                    transition={{
                      x: { type: "spring", stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 },
                    }}
                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                          <h3 className="text-base font-semibold">
                            入力内容を消去してよろしいですか？
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                          >
                            <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                              ×
                            </span>
                          </button>
                        </div>

                        <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                          <button
                            className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            style={{ transition: "all .15s ease" }}
                            onClick={() => setShowModal(false)}
                          >
                            　閉じる
                          </button>
                          <button
                            className="bg-red-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            style={{ transition: "all .15s ease" }}
                            onClick={() => (
                              methods.reset({}, { errors: false }),
                              setShowModal(false)
                            )}
                          >
                            入力内容を消去する
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </AnimatePresence>
            <form onSubmit={handleSubmit(onSubmit)} method="post" noValidate>
              <AnimatePresence>
                <FormProvider {...methods}>
                  {page === 1 && (
                    <motion.div
                      key="form1"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                    >
                      <Form />
                    </motion.div>
                  )}
                  {page === 2 && (
                    <motion.div
                      key="form2"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                    >
                      <Conf />
                    </motion.div>
                  )}
                  {page === 3 && (
                    <motion.div
                      key="form3"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                    >
                      <Success props={plan} />
                    </motion.div>
                  )}
                  {page === 4 && (
                    <motion.div
                      key="form3"
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                      }}
                    >
                      <Error />
                    </motion.div>
                  )}
                </FormProvider>
              </AnimatePresence>
              <div className="md:flex md:items-center py-4 bg-gray-100">
                <div className="w-full flex flex-row gap-4">
                  {page === 1 && (
                    <>
                      <div className="w-1/2 text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r bg-gray-400 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="button"
                          onClick={() => setShowModal(true)}
                        >
                          リセット
                        </button>
                      </div>
                      <div className="w-1/2 text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r from-pink-500 to-yellow-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="submit"
                          onClick={async (e) => {
                            const result = await trigger()
                            if (result) {
                              e.preventDefault()
                              setPage(2)
                              scrollTo("html")
                            }
                          }}
                          // onClick={() => {
                          //   setPage(3)
                          // }}
                        >
                          確認
                        </button>
                      </div>
                    </>
                  )}
                  {page === 2 && (
                    <>
                      <div className="w-1/2 text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r bg-gray-400 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault()
                            setPage(1)
                            scrollTo("html")
                          }}
                        >
                          キャンセル
                        </button>
                      </div>
                      <div className="w-1/2 text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r from-pink-500 to-yellow-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="submit"
                          disabled={promiseInProgress}
                          onClick={handleSubmit(onSubmit)}
                        >
                          送信
                        </button>
                      </div>
                    </>
                  )}
                  {/* {page === 3 && (
                    <>
                      <div className="w-full text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r from-pink-500 to-yellow-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="submit"
                          onClick={() => {
                            window.history.back()
                          }}
                        >
                          戻る
                        </button>
                      </div>
                    </>
                  )} */}
                  {page === 4 && (
                    <>
                      <div className="w-full text-center">
                        <button
                          className="w-full sm:w-1/3 shadow bg-gradient-to-r from-pink-500 to-yellow-500 hover:opacity-70 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault()
                            setPage(1)
                            scrollTo("html")
                          }}
                        >
                          トップへ戻る
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </>
  )
}

export default Index
